.products {
    margin-top: 15px;
    margin-bottom: 100px;
}

.products-actions {
    background-color: $white;
    border: 1px solid $light-grey;
    border-radius: $border-radius;
    padding: 16px 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.products-actions-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.products-actions-count {
    font-weight: 600;
    margin-right: 30px;
}

.products-actions-search {
    width: 500px;

    input {
        width: 100%;
        padding: 10px 14px;
        color: $black;
        border: 1px solid $light-grey;
        border-radius: $border-radius;
    }
}

.products-actions-view {
    display: flex;
    flex-direction: row;
}

.products-actions-order {
    span {
        margin-right: 6px;
    }

    select {
        border: 1px solid $light-grey;
        border-radius: $border-radius;
        height: 30px;
        background: $white;

        &:active, &:focus {
            outline: none;
        }
    }
}

.products-actions-template {
    margin-left: 40px;
    display: flex;
    align-items: center;
}

.products-actions-template-option {
    font-size: 1.2em;
    color: $light-grey;
    margin-right: 10px;

    &:last-child {
        margin-right: 0px;
    }

    &-active, &:hover {
        color: $light-grey-2;
    }
}

.product-element {
    margin-bottom: 20px;
}

.product {
    color: $black-2;
    border: 1px solid $light-grey;
    border-radius: $border-radius;
    background-color: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    width: 100%;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: $box-shadow;
    }
}

.product-id {
    width: 5%;
}

.product-image {
    width: 12%;
    text-align: center;

    img {
        width: auto;
        height: 64px;
    }
}

.product-title {
    flex-grow: 1;
}

.product-category {
    width: 25%;
}

.product-price {
    width: 10%;

    span:first-child {
        display: none;
    }
}

.product-quantity {
    width: 6%;

    span:first-child {
        display: none;
    }
}

.product-actions {
    display: flex;
    flex-direction: row;
    width: 8%;
    justify-content: flex-end;
}

.product-actions-delete,
.product-actions-edit {
    padding: 0px 6px;

    a {
        color: $black-2;

        &:hover {
            color: $main-color;
        }
    }
}

.products-grid-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .product-element {
        width: 33.33%;
        padding: 10px;
        margin-bottom: 0px;
    }

    .product {
        display: block;
        position: relative;
        height: 100%;
    }

    .product-id {
        display: none;
    }

    .product-image {
        width: inherit;
        text-align: center;
        margin-top: 20px;

        img {
            width: auto;
            height: 200px;
            padding: 20px 0px;
        }
    }

    .product-title {
        font-size: 1em;
        font-weight: 600;
        color: $black;
        margin-bottom: 3px;
    }

    .product-category {
        margin-bottom: 6px;
        width: 100%;
    }

    .product-price {
        width: inherit;
        margin-bottom: 2px;

        span:first-child {
            display: inline;
            font-weight: 600;
        }
    }

    .product-quantity {
        width: inherit;

        span:first-child {
            display: inline;
            font-weight: 600;
        }
    }

    .product-actions {
        display: inline-block;
        position: absolute;
        width: auto;
        top: 24px;
        right: 24px;
    }

    .product-actions-delete,
    .product-actions-edit {
        display: inline-block;
    }

    .product-actions-edit {
        padding-right: 0px;
    }
}
