.categories-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.category-element {
    display: flex;
    flex-grow: 1;
    width: 50%;
    padding: 10px;
    margin-bottom: 0px;
}

.category {
    display: block;
    position: relative;
    color: $black-2;
    border: 1px solid $light-grey;
    border-radius: $border-radius;
    background-color: $white;
    padding: 30px;
    width: 100%;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: $box-shadow;
    }
}

.category-title {
    margin-bottom: 10px;
}

.category-description {
    margin-bottom: 20px;
}

.category-actions {
    display: inline-block;
    position: absolute;
    width: auto;
    top: 24px;
    right: 24px;

    div {
        display: inline-block;
        padding: 0px 2px;

        a {
            color: $black-2;

            &:hover {
                color: $main-color;
            }
        }

        &:last-child {
            padding-right: 0px;
        }
    }
}
