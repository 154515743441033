.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $sidebar-width;
    background-color: $second-color;
}

.sidebar-logo {
    margin-top: 60px;
    margin-bottom: 40px;
    padding: 0px 50px;

    img {
        width: 100%;
        height: auto;
    }
}

.sidebar-nav {
    width: 100%;

    nav, ul, li, a {
        width: 100%;
    }

    a {
        display: block;
        padding: 15px 0px 15px 30px;
        font-size: 1.04em;
        color: $light-grey-2;
        transition: all 0.1s ease-in-out;

        i {
            padding-right: 4px;
        }

        &:hover {
            cursor: pointer;
            background-color: $second-color-hover;
        }

        &.active {
            background-color: $second-color-hover;
        }
    }
}
