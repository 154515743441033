* {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

html, body {
    font-family: $main-font;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

body {
    background-color: $grey;
    color: $black-2;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    line-height: 1.2;
    color: $black;
}

a {
    font-size: 1em;
    text-decoration: none;
    color: $main-color;
    transition: $transition;

    &:hover {
        color: $main-color-hover;
        transition: $transition;
    }
}

span, p, li {
    line-height: 1.5;
}

.sub {
    font-size: 0.9em;
    color: $black-2;
}

input:focus {
    outline: none;
}

::selection {
    background-color: $main-color;
    color: $white;
}

::-moz-selection {
    background-color: $main-color;
    color: $white;
}

.main-action {
    position: fixed;
    bottom: 34px;
    right: 34px;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    box-shadow: $box-shadow-action;
    transition: $transition;
    display: flex;
    align-items: center;
    justify-content: center;

    &-success {
        background-color: $success-color;

        &:hover {
            background-color: $success-color-hover;
        }
    }

    &-danger {
        background-color: $danger-color;

        &:hover {
            background-color: $danger-color-hover;
        }
    }

    &:hover {
        box-shadow: $box-shadow-action-hover;
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3em;
        color: $white;
    }

    button {
        display: block;
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        font-size: 1.3em;
        color: $white;

        &:hover {
            cursor: pointer;
        }

        &:focus, &:active {
            outline: none;
        }
    }
}
