.u-inactive {
    display: none !important;
    visibility: hidden !important;
}

.u-hidden {
    visibility: hidden;
    pointer-events: none;
}

.u-unselectable {
    cursor: default;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.u-unclickable {
    pointer-events: none;
    cursor: default;
}

.u-fullSize,
.u-fullParent {
    width: 100%;
    height: 100%;
}

.u-fullParent {
    position: absolute;
    top: 0;
    left: 0;
}

.u-fluid--w {
    width: 100%;
    height: auto;
}

.u-fluid--h {
    width: auto;
    height: 100%;
}

.u-topCorner,
.u-topCorner--right {
    position: absolute;
    top: 0;
}

.u-topCorner--right {
    right: 0;
}

.u-center--rel {
    display: block;
    margin: auto;
}

.u-center--abs {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}

.u-middle--abs {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
}

.u-inlineList {
    position: relative;
}

.u-inlineList-item,
.u-inlineList-item--top,
.u-inlineList-item--middle,
.u-inlineList-item--bottom {
    position: relative;
    display: inline-block;
}

.u-inlineList-item--top {
    vertical-align: top;
}

.u-inlineList-item--middle {
    vertical-align: middle;
}

.u-inlineList-item--bottom {
    vertical-align: bottom;
}

.u-table {
    display: table;
}

.u-table-cell,
.u-table-cell--center,
.u-table-cell--middle {
    display: table-cell;
}

.u-table-cell--center,
.u-table-cell--middle {
    vertical-align: middle;
}

.u-table-cell--center {
    text-align: center;
}

.u-cf {
    zoom: 1;
}

.u-cf:before,
.u-cf:after {
    content: " ";
    display: table;
}

.u-cf:after {
    clear: both;
}

.u-centerBg {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}

.u-antialiasing {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

.u-resetBtn {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    white-space: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
}

.u-resetBtn:not(input) {
    user-select: none;
}

.u-resetBtn::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.u-resestBtn--withoutOutline {
    outline: none;
}

.u-img--rounded {
    border-radius: $border-radius;
}

.u-img--circle {
    border-radius: 50%;
}

.u-img--responsive {
    max-width: 100%;
    height: auto;
    display: block;
}
