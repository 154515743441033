.errors {
    background-color: $danger-color;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: $border-radius;
    color: $white;
}

.errors-border {
    border-color: $danger-color !important;
}
